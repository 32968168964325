import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';
import ToggleStatus from "./toggle-status.component";
import Modal from 'react-modal';

const Dashboard = () => {
    const navigate = useNavigate();
    const [userrole, setUserrole] = useState('');
    const [username, setUsername] = useState('');
    const [areals, setAreals] = useState([]);
    const [favoriteAreals, setFavoriteAreals] = useState([]);
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [loading, setLoading] = useState(true);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [arealToDelete, setArealToDelete] = useState(null);

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '600px'
        },
    };

    useEffect(() => {
        const fetchUserrole = () => {
            const token = localStorage.getItem('authToken');
            try {
                const decodedToken = jwtDecode(token);
                setUserrole(decodedToken.userrole);
                fetchAreals();
                fetchBidderAreals();
            } catch (error) {
                console.error('Ungültiges Token:', error);
                localStorage.removeItem('authToken');
                navigate('/login');
            }
        };
        const fetchUsername = () => {
            const token = localStorage.getItem('authToken');
            try {
                const decodedToken = jwtDecode(token);
                setUsername(decodedToken.username);
            } catch (error) {
                console.error('Ungültiges Token:', error);
                localStorage.removeItem('authToken');
                navigate('/login');
            }
        };
        const fetchAreals = async () => {
            try {
                setLoading(true);
                const token = localStorage.getItem('authToken');
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/areal/dashboard`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
              //  setAreals(response.data);

                const arealsWithImages = await Promise.all(response.data.map(async (areal) => {
                    if (areal.imageOrder && areal.imageOrder.length > 0) {
                        try {
                            // Versuch, das Bild vom Server zu holen
                            const imageResponse = await axios.get(`${process.env.REACT_APP_API_URL}/files/${areal._id}/private/images/${areal.imageOrder[0]}`, {
                                headers: {
                                    'Authorization': `Bearer ${token}`
                                }
                            });
                            // Wenn erfolgreich, füge das Bild in die Areal-Daten ein
                            return { ...areal, base64Image: imageResponse.data.image };
                        } catch (error) {
                            // Wenn das Bild nicht vorhanden ist, fange den Fehler ab und lasse base64Image leer
                            console.error(`Fehler beim Laden des Bildes für Areal ${areal._id}:`, error);
                            return { ...areal, base64Image: null };  // Keine Base64-Daten, wenn Bild nicht vorhanden
                        }
                    }
                    return areal;
                }));
                setAreals(arealsWithImages);
                setLoading(false);
            } catch (error) {
                const errorMessage = error.response.data.message
                if(!errorMessage)
                {
                    setErrorMessage("Unbekannter Fehler.");
                    setLoading(false);
                }
                else
                {
                    setErrorMessage(errorMessage);
                    setLoading(false);
                }
            }
        };
        const fetchBidderAreals = async () => {
            try {
                setLoading(true);
                const token = localStorage.getItem('authToken');
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/areal/bidder/dashboard`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                const arealsWithImages = await Promise.all(response.data.map(async (areal) => {
                    if (areal.imageOrder && areal.imageOrder.length > 0) {
                        try {
                            return { ...areal, imageUrl: `${process.env.REACT_APP_API_URL}/files/${areal._id}/images/${areal.imageOrder[0]}` };
                        } catch (error) {
                            console.error(`Fehler beim Laden des Bildes für Areal ${areal._id}:`, error);
                            return { ...areal, imageUrl: null };
                        }
                    }
                    return areal;
                }));
                setFavoriteAreals(arealsWithImages);
                setLoading(false);
            } catch (error) {
                const errorMessage = error.response.data.message
                if(!errorMessage)
                {
                    setErrorMessage("Unbekannter Fehler.");
                    setLoading(false);
                }
                else
                {
                    setErrorMessage(errorMessage);
                    setLoading(false);
                }
            }
        };
        fetchUserrole();
        fetchUsername();
    }, [navigate]);

    const openModal = (arealId) => {
        setArealToDelete(arealId);
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setArealToDelete(null);
    };

    const handleDelete = async () => {
        try {
            const token = localStorage.getItem('authToken');
            await axios.delete(`${process.env.REACT_APP_API_URL}/areal/${arealToDelete}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            setSuccessMessage('Konzeptvergabe gelöscht');
            setAreals(areals.filter(areal => areal._id !== arealToDelete));
        } catch (error) {
            console.error('Fehler beim Löschen der Konzeptvergabe:', error);
            setErrorMessage('Fehler beim Löschen der Konzeptvergabe');
        } finally {
            closeModal();
        }
    };


    return (
        <div className="container mt-5">
            <h2 className="text-center mb-4">Dashboard</h2>
            {/* Fehlermeldung anzeigen */}
            {errorMessage && (
                <div className="alert alert-danger" role="alert">
                    {errorMessage}
                </div>
            )}

            {/* Erfolgsmeldung anzeigen */}
            {successMessage && (
                <div className="alert alert-success" role="alert">
                    {successMessage}
                </div>
            )}
            <div className="row">
                <div className="col-6">
                    <p>Hallo {username}!</p>
                </div>

                {userrole ==='Anbieter' && (<div className="col-6">
                    <a href="#"
                       className="greenlink float-end"
                       onClick={() => navigate(`/create`)}>
                        Neue Konzeptvergabe
                    </a>
                </div>)}

            </div>

            {userrole === 'Anbieter' ? (
                <div className="row">
                    {loading ? (
                        <p className="text-center">Lade Inserate...</p>
                    ) : (
                        areals.length > 0 ? (
                                areals.map((areal) => (
                                    <div className="col-md-4" key={areal._id}>
                                        <div className="card mb-4">
                                            {areal.imageOrder && areal.imageOrder.length > 0 && areal.base64Image ? (
                                                <div className="card-img-container">
                                                <img
                                                    src={`${areal.base64Image}`}
                                                    className="card-img-top"
                                                    alt="Areal"
                                                />
                                                </div>
                                            ) : null}

                                                <div className="card-body">
                                                <div className="row align-items-start">
                                                <h5 className="card-title">{areal.name}</h5>
                                                <p className="card-text">
                                                <ToggleStatus arealId={areal._id} initialStatus={areal.status}/>
                                    </p>
                            <div className="col-8">
                                <a className="greenlink"
                                   onClick={() => navigate(`/preview/${areal._id}`)}>
                                                            Vorschau und bearbeiten
                                                        </a>

                                                    </div>
                                                    <div className="col-4 text-end">
                                                        <a className="greenlink"
                                                           onClick={() => openModal(areal._id)}>
                                                            Löschen
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                        ) : (
                            <p className="text-center">Keine aktive Konzeptvergabe</p>
                        )
                    )}
                </div>
            ) : (
                <div className="row">
                    {loading ? (
                        <p className="text-center">Lade Inserate...</p>
                    ) : (
                        favoriteAreals.length > 0 ? (
                            favoriteAreals.map((favoriteAreal) => (
                                <div className="col-md-4" key={favoriteAreal._id}>
                                    <div className="card mb-4">
                                        {favoriteAreal.imageOrder && favoriteAreal.imageOrder.length > 0 && favoriteAreal.imageUrl ? (
                                            <div className="card-img-container">
                                                <img
                                                    src={`${favoriteAreal.imageUrl}`}
                                                    className="card-img-top"
                                                    alt="Areal"
                                                />
                                            </div>
                                        ) : null}

                                        <div className="card-body">
                                            <h5 className="card-title">{favoriteAreal.name}</h5>
                                            <p className="card-text">{favoriteAreal.comment}</p>
                                            {/* Link zur Detailseite des Areals */}
                                            <a href={`/areal/${favoriteAreal._id}`} className="greenlink">Mehr erfahren</a>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p className="text-center">Keine favorisierten Konzeptvergabe</p>
                        )
                    )}
                </div>
            )}

            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Konzeptvergabe löschen"
                ariaHideApp={false}
                style={customStyles}>
                <h6>Bitte bestätigen</h6>
                <p>Möchten Sie diese Konzeptvergabe unwiderruflich löschen?</p>
                <div className="modal-buttons">
                    <button onClick={handleDelete} className="btn btn-danger">Löschen</button>
                    &nbsp;
                    <button onClick={closeModal} className="btn btn-secondary">Abbrechen</button>
                </div>
            </Modal>
        </div>
    );
};

export default Dashboard;
