import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import {jwtDecode} from "jwt-decode";
import {FaRegStar, FaStar} from "react-icons/fa";

const ArealPage = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [areal, setAreal] = useState(null);
    const [images, setImages] = useState([]);
    const [documents, setDocuments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState(null);
    const [userrole, setUserrole] = useState("");
    const [isFavorited, setIsFavorited] = useState(false);

    useEffect(() => {
        const fetchAreal = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/areal/object/${id}`);
                setAreal(response.data);

                const [imagesResponse, documentsResponse] = await Promise.all([
                    axios.get(`${process.env.REACT_APP_API_URL}/files/${id}/images`),
                    axios.get(`${process.env.REACT_APP_API_URL}/files/${id}/documents`)
                ]);
                const formattedImages = imagesResponse.data.map(image => ({
                    original: `${process.env.REACT_APP_API_URL}/files/${id}/images/${image}`,
                    thumbnail: `${process.env.REACT_APP_API_URL}/files/${id}/images/${image}`,
                }));
                const loadImages = formattedImages.map(img => {
                    return new Promise((resolve, reject) => {
                        const imgElement = new Image();
                        imgElement.src = img.original;
                        imgElement.onload = resolve; // Bild erfolgreich geladen
                        imgElement.onerror = reject; // Fehler beim Laden
                    });
                });
                await Promise.all(loadImages);
                setImages(formattedImages);
                setDocuments(documentsResponse.data);
                setLoading(false);
                
            } catch (error) {
                console.error("Fehler beim Laden des Areals:", error);
                setErrorMessage("Areal konnte nicht gefunden werden");
                setLoading(false);
            }
        };
        const fetchUserrole = () => {
            const token = localStorage.getItem('authToken');
            if(token)
            {
                try {
                    const decodedToken = jwtDecode(token);
                    setUserrole(decodedToken.userrole);
                } catch (error) {
                    console.error('Ungültiges Token:', error);
                    localStorage.removeItem('authToken');
                    navigate('/login');
                }
            }
        };
        fetchAreal();
        fetchUserrole();
    }, [id]);

    useEffect(() => {
        if (userrole === 'Bewerber') {
            const checkIfFavorited = async () => {
                const token = localStorage.getItem('authToken');
                if (token) {
                    try {
                        const config = {
                            headers: { Authorization: `Bearer ${token}` }
                        };
                        const responseFav = await axios.get(`${process.env.REACT_APP_API_URL}/user/favorites`, config);
                        const favoritedAreals = responseFav.data;
                        if (favoritedAreals) {
                            const isFavorited = favoritedAreals.includes(id)
                            setIsFavorited(isFavorited);
                        }
                    } catch (error) {
                        console.error('Fehler beim Abrufen des Favoritenstatus:', error);
                    }
                }
            };
            checkIfFavorited();
        }
    }, [userrole, id]);

    const toggleFavorite = async () => {
        const token = localStorage.getItem('authToken');
        if (token) {
            try {
                const config = {
                    headers: { Authorization: `Bearer ${token}` }
                };
                if (isFavorited) {
                    await axios.delete(`${process.env.REACT_APP_API_URL}/user/favorites/${id}`, config);
                } else {
                    await axios.post(`${process.env.REACT_APP_API_URL}/user/favorites/${id}`, {}, config);
                }
                setIsFavorited(!isFavorited);
            } catch (error) {
                console.error('Fehler beim Aktualisieren des Favoritenstatus:', error);
            }
        }
    };

    if (loading) {
        return (
            <div className="container mt-5 text-center">
                <h2>Laden...</h2>
            </div>
        );
    }

    if (errorMessage) {
        return (
            <div className="alert alert-danger" role="alert">
                {errorMessage}
            </div>
        );
    }

    return (
        <div className="container mt-5">
            <div className="row">
            <div className="col-md-8">
                    <div className="card mb-4">
                        {images.length > 0 && (
                            <>
                                <ImageGallery items={images} showThumbnails={true} showFullscreenButton={true}/>
                                <hr/>
                            </>
                        )}
                        <div className="card-body">
                            <div className="row">
                                <div className="title-container card-text">
                                    <h3 className="card-title">{areal.name}</h3>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <p className="card-text">
                                        <strong>Nutzung:</strong> {areal.usage}
                                    </p>
                                    <p className="card-text">
                                        <strong>Baurecht:</strong> {areal.law}
                                    </p>
                                    <p className="card-text card-last-with-margin display-linebreak">
                                        <strong>Baurecht Kommentar:</strong> {areal.lawComment}
                                    </p>
                                </div>
                                <div className="col-md-6">
                                    <p className="card-text display-linebreak">
                                        <strong>Kommentar:</strong> {areal.comment}
                                    </p>
                                    <p className="card-text">
                                        <strong>Mindestpreis:</strong> {areal.minPrice} €
                                    </p>
                                </div>
                            </div>
                            <br/>
                            <div className="row">
                                <p className="card-text display-linebreak">
                                    <strong>Beschreibung:</strong><br/>{areal.description}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-4">
                    <div className="card mb-4">
                        <div className="card-header">
                            <h5>Infos zur Kommune</h5>
                        </div>
                        <div className="card-body">
                            <p><strong>Inseriert von: </strong>{areal.ownerCity}</p>
                            <p><strong>E-Mail: </strong>
                                {areal.ownerEmails.map((email, index) => (
                                    <a
                                        key={index}
                                        href={`mailto:${email}`}
                                        className="greenlink"
                                    >
                                        {`${email}; `}
                                    </a>
                                ))}
                            </p>
                        </div>
                    </div>
                    {userrole === 'Bewerber' && (
                        <div className="card mb-4">
                            <div className="card-header">
                                <h5>Favorisieren & Anfragen</h5>
                            </div>
                            <div className="card-body">
                            <a onClick={toggleFavorite} className="favorite-button">
                                {isFavorited ? <><FaStar className="star-icon"/>Favorit entfernen</> : <><FaRegStar
                                    className="star-icon"/>Favorit hinzufügen</>}
                            </a>
                        </div>
                    </div>
                    )}
                    {documents.length > 0 && (
                        <div className="card">
                        <div className="card-header">
                                <h5>Dokumente</h5>
                            </div>
                            <div className="card-body">
                                {documents.map((doc, index) => (
                                    <div key={index}>
                                        <a
                                            href={`${process.env.REACT_APP_API_URL}/files/${id}/documents/${doc}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="greenlink"
                                        >
                                            {doc};
                                        </a>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <div className="mt-5 text-center">
                <button className="btn btn-secondary" onClick={() => navigate(-1)}>
                    Zurück zur Übersicht
                </button>
            </div>
        </div>
    );
};

export default ArealPage;
